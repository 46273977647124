const themeConfig = {
    'default': {
      'logo': 'twentyea-logo.png',
      'scssVariables': 'src/assets/scss/default',
    },
    'convey': {
      'logo': 'twentyea-logo-convey.png',
      'scssVariables': 'src/assets/scss/convey',
    }
  };
  module.exports = { themeConfig }
