import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/third-party/authenticate',
    name: 'third-party.authenticate',
    component: () => import('../views/Auth/ThirdPartyLogin.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME + ' Login',
      guest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/Login.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME + ' Login',
      guest: true
    }
  },
  {
    path: '/password/reset',
    name: 'password.reset',
    component: () => import('../views/Auth/ForgotPassword.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME + ' Recover Password',
      guest: true
    }
  },
  {
    path: '/password/recover',
    name: 'password.recover',
    component: () => import('../views/Auth/RecoverPassword.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME + ' Recover Password',
      guest: true
    }
  },
  {
    path: '/password/new',
    name: 'password.new',
    component: () => import('../views/Auth/RecoverPassword.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME + ' New Password',
      guest: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: process.env.VUE_APP_BRAND_NAME,
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Page not found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'third-party.authenticate') {
    next();
    return;
  }

  const token = localStorage.getItem('token')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
        query: to.query
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (token == null) {
      next()
    } else {
      next({ name: 'home' })
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = to.meta.title
  }
});

export default router
