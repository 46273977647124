<template>
  <div id="app">
    <div id="nav" class="navbar-om">
      <b-container fluid="xl">
        <b-navbar toggleable="md" type="dark">
          <b-navbar-brand>
            <router-link to="/">
              <img :src="logo" alt="Prospect" />
            </router-link>
            <b-icon
              class="eastbay-color"
              icon="bullseye"
              font-scale="2"
            ></b-icon>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav v-if="authCheck" class="ml-auto">
              <b-nav-item-dropdown text="Lang" right class="dropdown--no-caret">
                <template slot="button-content">
                  <b-icon icon="list" class="om-color"></b-icon>
                  Menu
                </template>
                <b-dropdown-item
                  ><strong>{{ user.email }}</strong></b-dropdown-item
                >
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-container>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { themeConfig } from "@/config/theme";
const logo = themeConfig[process.env.VUE_APP_THEME].logo;
export default {
  data() {
    return {
      logo: require(`@/assets/${logo}`),
    };
  },
  computed: {
    ...mapState({
      authCheck: (state) => !!state.auth.token,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }))
        .catch((error) => console.error(error));
    },
  },
};
</script>
<style lang="scss">
#nav {
  border-bottom: 2px var(--om-color) solid;
  nav {
    padding: 0.5rem 0;
  }
  .dropdown--no-caret .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    max-height: 468px;
    overflow-y: auto;
    z-index: 1101;
  }
  .dropdown-item {
    padding: 0 1rem;
  }
}
.navbar-om {
  background-color: var(--eastbay-color);
  .nav-link {
    color: #ffffff !important;
  }
}
</style>
