import _ from 'lodash'
import axios from 'axios'
import router from '../router'
import store from '../store'

export default {
  setupInterceptors: () => {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    axios.defaults.transformRequest = [(data, headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.common['Authorization'] = `Bearer ${token}`
      }
      headers.common["Accept"] = 'application/json'
      return data
    }, ...axios.defaults.transformRequest]

    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        let msg = _.get(error, 'response.data', {})
        msg = msg.message || msg.error
        if (error.response.status === 401 && localStorage && ('Unauthorized' === msg || 'Unauthenticated.' === msg)) {
          store.dispatch('auth/logout')
          router.go()
        }
        return Promise.reject(error.response)
      }
    )
  }
}
