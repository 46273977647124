import axios from "axios";

const USER_KEY = 'user'
const TOKEN_KEY = 'token'

const state = {
  token: localStorage.getItem(TOKEN_KEY),
  user: localStorage.getItem(USER_KEY) ? JSON.parse(localStorage.getItem(USER_KEY)) : {},
  current_branch: {},
  status: '',
}

const getters = {
  //
}

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/login', user)
        .then(response => {
          commit('auth_success', response.data.access_token)
          resolve(response)
        })
        .catch(error => {
          commit('auth_error')
          reject(error)
        })
    })
  },

  user({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/user')
        .then(response => {
          const user = response.data
          commit('set_user', user)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  logout({ commit }) {
    return new Promise((resolve,) => {
      commit('logout')
      resolve(true);
    })
  },

  forgotPassword({ commit }, data) {
    commit('doNothing')
    return new Promise((resolve, reject) => {
      axios.post('/forgot-password', data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  resetPassword({ commit }, data) {
    commit('doNothing')
    return new Promise((resolve, reject) => {
      axios.post('/reset-password', data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

const mutations = {
  auth_request(state) {
    state.status = 'loading'
  },
  auth_success(state, token,) {
    state.status = 'success'
    state.token = token
    localStorage.setItem(TOKEN_KEY, token)
  },
  auth_error(state) {
    state.status = 'error'
    localStorage.removeItem(TOKEN_KEY)
  },
  set_user(state, user) {
    state.user = user
    state.current_branch = user.current_branch
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  },
  logout(state) {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_KEY)
    state.status = ''
    state.token = ''
    state.user = {}
    state.current_branch = {}
  },
  doNothing() {},
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
